import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html'
})
export class PricingComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-center',
    title: 'Precios',
    subTitle: 'Planes de Servicio',
  };

  List: any[] = [
    {
      title: "Estándar",
      subtitle: 'Mínimo: 40 estudiantes',
      description: "Por estudiante",
      price: "Q11.00",
      plan_desc: "/Mes",
      href: "#",
      services: [
        'Administración General','Cuenta Corriente',
        'Control Académico','Padres'        
      ],
      nonservices: ['E-learning','Herramientas Profesionales']
    },
    {
      title: "Pro",
      subtitle: 'Mínimo: 80 estudiantes',
      description: "Por estudiante",
      price: "Q13.00",
      plan_desc: "/Mes",
      href: "#",      
      services: ['Administración General','Cuenta Corriente',
        'Control Académico','Padres',
        'E-learning'
      ],
      nonservices: ['Herramientas Profesionales']
    },
    
    {
      title: "Premium",
      subtitle: 'Mínimo: 120 estudiantes',
      description: "Por estudiante",
      price: "Q18.00",
      plan_desc: "/Mes",
      active: true,
      href: "#",      
      services: ['Administración General','Cuenta Corriente',
        'Control Académico','Padres',
        'E-learning', 'Herramientas Profesionales'
      ]
    }
  ]
  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html'
})
export class PartnerComponent implements OnInit {

  List: any[] = [
    {
      title: 'Administración General',
      item: [
          "Manejo de roles y usuarios",
          "Información de padres y estudiantes",
          "Configuración de grados, carreras, secciones y materias",
          "Manejo de ciclos escolares",
          "Bitácora de transacciones por usuario",
          "Dashboard"
        ],
      image: './assets/images/scholae/administracion.jpg',
      width: 175
    },
    {
      title: 'Cuenta Corriente',
      item: [
        "Configuración de servicios de la institución educativa",
        "Cargos periódicos y automáticos en inscripción y colegiaturas",
        "Comprobante de abonos en PDF","Estados de cuenta en tiempo real",
        "Control de servicios exentos y gravados",
        "Reporte de saldos, cobros y abonos",
        "Facturación electrónica FEL"
      ],
      image: './assets/images/scholae/finanzas.jpg',
      width: 229
    },
    {
      title: 'Control Académico',
      item: [
        "Inscripción de estudiantes",
        "Creación de ponderaciones generales por curso",
        "Asistencia",
        "Acta de notas",
        "Reportes de promedios y estadísticas",
        "Generación de certificado de notas en PDF",
        "Observaciones por estudiante"
      ],
      image: './assets/images/scholae/academico.jpg',
      width: 168
    },  
    {
      title: 'Padres',
      item: [
        "Información por estudiante",
        "Visualización de notas",
        "Estado de cuenta por estudiante",
        "Visualización de observaciones por estudiante",
        "Comprobantes de pago",
        "Saldo total",
        "Avisos generales"
      ],
      image: './assets/images/scholae/padres.jpg',
      width: 212
    },  
    {
      title: 'E-Learning',
      item: [
        "Creación de cursos virtuales e inscripción de estudiantes",
        "Publicación de material educativo",
        "Calendario general",
        "Notificaciones por curso",
        "Actividades interactivas para hacer evaluaciones, tareas o ejercicios",
        "Accesibilidad desde navegador web o a través de app iOS o Android"
      ],
      image: './assets/images/scholae/elearning.jpg',
      width: 199
    },
    {
      title: 'Herramientas Profesionales',
      item: [
        "Cuentas profesionales con dominio del colegio para profesores*",
        "30 GB de almacenamiento en la nube para cada profesor",
        "Videollamadas Google Meet Business de hasta 150 participantes",
        "Herramientas de videollamada como: Sesiones grupales y Encuestas",
        "Herramientas Breakout rooms y encuestas durante la clase",
        "Grabaciones de clase agrupadas en E-Learning"
      ],
      image: './assets/images/scholae/herramientas.jpg',
      width: 199
    }
  ]
  constructor() { }

  ngOnInit() {
  }

}

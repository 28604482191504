import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specializing',
  templateUrl: './specializing.component.html'
})
export class SpecializingComponent implements OnInit {

  titleSectionProp: any = {
    title: 'Planes',
    subtitle: 'Consideraciones',
    list:[
      'Si requieres un plan personalizado que se adapte a tus necesidades contáctanos.',
      'Incluye 2 DTE (Factura, Nota de crédito, etc) por estudiante al mes, acumulables durante el año y transferibles entre estudiantes.',
      'Usuario administrativos y profesores, no se cobran.',
      'El cobro es de enero a octubre.',
      'El plan es sin contrato fijo, el establecimiento puede darse de baja en cualquier momento (15 días de anticipación).',
      'El plan premium tiene un costo adicional de implementación de Q200.00 por dominio del colegio.'
    ]
  };
  
  constructor() { }

  ngOnInit() {
  }

}

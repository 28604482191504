import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';
import { RouterModule, Routes } from '@angular/router';
import { XaminModule } from '../xamin/xamin.module';
import { RevolutionSlider1Module } from './components/revolution-slider1/revolution-slider1.module';
import { SectionVerticalOneComponent } from './components/section-vertical-one/section-vertical-one.component';
import { SectionVerticalTwoComponent } from './components/section-vertical-two/section-vertical-two.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AboutUsComponent } from './components/about-us/about-us.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { SpecializingComponent } from './components/specializing/specializing.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { PartnerComponent } from './components/partner/partner.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    children: [
    ]
  }
];

@NgModule({
  declarations: [
    IndexComponent,
    SectionVerticalOneComponent,
    SectionVerticalTwoComponent, 
    PricingComponent,
    SpecializingComponent,
    ContactFormComponent,
    PartnerComponent,
    AboutUsComponent
  ],
  exports: [
    SectionVerticalOneComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    XaminModule,
    RevolutionSlider1Module,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class LandingPage1Module { }

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Contacto } from '../interfaces/interfaces';

@Injectable({ providedIn: 'root' })
export class FireStoreService 
{
    constructor(private firestore: AngularFirestore) 
    { 
        
    }

    createContacto(contacto: Contacto)
    {
        console.log("contacto", contacto);
        return this.firestore.collection('contacto').add(contacto);
    }

}
const topMenuBarItems = [
  {
    href: '#inicio',
    title: 'Inicio',
    isCurrent: true
  },
  {
    href: '#modulos',
    title: 'Módulos',
    isCurrent: false
  },
  {
    href: '#info-adicional',
    title: 'Información Adicional',
    isCurrent: false
  },
  {
    href: '#precio',
    title: 'Precio',
    isCurrent: false
  },
  {
    href: '#contacto',
    title: 'Contacto',
    isCurrent: false
  },
  {
    href: '/privacy-policy',
    title: 'Privacidad',
    isCurrent: false
  }
];

const servicesMenu =  [
  {
    href: '/services/data-analytics',
    title: 'Data Analytics'
  },
  {
    href: '/services/managed-analytics',
    title: 'Managed Analytics'
  },
  {
    href: '/services/big-data-services',
    title: 'Big Data Services'
  },
  {
    href: '/services/data-science-consulting',
    title: 'Data Science Consulting'
  },
  {
    href: '/services/business-intelligence',
    title: 'Business Intelligence'
  },
  {
    href: '/services/data-visualization-services',
    title: 'Data Visualization'
  },
  {
    href: '/services/data-management',
    title: 'Data Management'
  },
  {
    href: '/services/artificial-intelligence',
    title: 'Artificial Intelligence'
  }
];

export { servicesMenu, topMenuBarItems };
  
import { Component, OnInit } from '@angular/core';
import { Contacto } from '../../../interfaces/interfaces';
import { FireStoreService } from '../../../services/services';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html'
})
export class ContactFormComponent implements OnInit {

  objContacto: Contacto = { nombre: null, email: null, mensaje: null };
  blnSend: boolean = false;
  blnSuccess: boolean = false;

  constructor(private fireStoreService: FireStoreService) { }

  ngOnInit() {
  }

  sendData()
  {
    this.blnSend = true;
    if(!this.objContacto.nombre || !this.objContacto.email || !this.objContacto.mensaje) return;
    this.fireStoreService.createContacto(this.objContacto);
    this.blnSuccess = true;
    this.objContacto = { nombre: null, email: null, mensaje: null };
    let delay = setTimeout(() => {
      this.blnSend = false;
      this.blnSuccess = false;
      clearTimeout(delay);
    }, 10000);
  }

}
